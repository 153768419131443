import React from 'react'

function ContactCard({ className, children, link, href, description }) {
    return (
        <div className={`w-10/12 mx-auto lg:mx-8 mb-8 lg:w-1/2 flex flex-col lg:items-center lg:text-center ${className}`}>
            {children}
            <p className='text-lg mt-4 mb-2 uppercase mx-auto text-brand '>{description}</p>
            <a href={href} className='text-text-gray text-center text-xl'>{link}</a>
        </div>
    )
}

export default ContactCard