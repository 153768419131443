import React from 'react'
import VideoCard from './VideoCard'

function VideoPlayer({ children, videos }) {
    return (
        <div className='flex items-start flex-wrap justify-center w-full'>
            {/* <div className='w-1/3'>
                <div className='flex items-center'>
                    <ImageWrapper fill src={videos[0].thumbnail} className='w-1/2' />
                    <ImageWrapper fill src={videos[1].thumbnail} className='w-1/2' />
                </div>
                <ImageWrapper fill src={videos[2].thumbnail} className='w-full' />
            </div>
            <div className='w-1/3'>
                <ImageWrapper fill src={videos[3].thumbnail} className='w-full' />
                <div className='flex items-center'>
                    <ImageWrapper fill src={videos[4].thumbnail} className='w-1/2' />
                    <ImageWrapper fill src={videos[5].thumbnail} className='w-1/2' />
                </div>
            </div>
            <div className='w-1/3'>
                <ImageWrapper fill src={videos[6].thumbnail} className='w-full' />
            </div> */}
            {
                videos.map(video => {
                    return <VideoCard key={video.videoId} video={video} className="w-full lg:w-1/4" />
                })
            }
            {children}
        </div>
    )
}

export default VideoPlayer