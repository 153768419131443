const process = [
    {
        id: "01",
        heading: "Preparation",
        description: "This process involves a series of steps to ensure everything is ready for the shoot, including developing a concept, writing a script, creating storyboards, scouting locations, casting actors, securing equipment, and rehearsing."
    },
    {
        id: "02",
        heading: "Shooting",
        description: "This is the day where all the planning and pre-production comes together to film the video."
    },
    {
        id: "03",
        heading: "Final steps",
        description: "This process involves editing the footage, adding special effects, composing the soundtrack, and finalizing the video to get it ready for release or distribution."
    },
]

module.exports = process;