import React, { useState } from 'react';
import ImageWrapper from './ImageWrapper'
import '../modal.css'

function VideoCard({ video, className }) {
    const [open, setOpen] = useState(false)

    return (
        <div className={`m-2 ${className}`}>
            <ImageWrapper onClick={() => setOpen(!open)} fill src={video.thumbnail} alt="About Me" className='group relative cursor-pointer w-full mx-auto lg:mx-0 lg:w-full lg:h-[300px]'>

                <div className='absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 duration-500 bg-gradient-to-t from-black to-transparent'>
                    {/* <div className='flex flex-col justify-center transition-all group-hover:opacity-100 opacity-0 absolute w-full translate-x-1/2 lg:w-full h-full bg-gradient-to-t from-black to-transparent top-0 right-1/2'> */}
                    <h2 className='text-center text-3xl font-bold mb-4 text-white'>{video.label}</h2>
                </div>
            </ImageWrapper>

            {
                open ? (
                    <section className="modal__bg">
                        <div className="modal__align">
                            <div className="modal__content" modal={open}>
                                <div
                                    className="modal__close"
                                    arial-label="Close modal"
                                    onClick={() => setOpen(false)}
                                >&times;</div>
                                <div className="modal__video-align">
                                    <video preload="none" className="modal__video-style" src={video.src} autoPlay controls />
                                </div>
                            </div>
                        </div>
                    </section>

                ) : null
            }
        </div>
    )
}

export default VideoCard