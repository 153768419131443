import React from 'react'

function TextCard({ className, label, heading, description }) {
    return (
        <div className={`flex flex-col items-start justify-center py-4 ${className}`}>
            <div className='flex items-center'>
                <h2 className='font-sq mb-2 text-text-black uppercase flex items-center text-2xl'><span className='w-12 font-bold grid place-items-center h-12 rounded-full bg-brand text-white mr-2'>{label}</span>{heading}</h2>
            </div>
            <p className='text-lg'>{description}</p>
        </div>
    )
}

export default TextCard