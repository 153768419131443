import NavLink from "./NavLink"
import SocialLinks from "./SocialLinks"

function MobileNavbar({ enabled, toggle }) {
    return (
        <div className={`lg:hidden w-full transition-all ${enabled ? `z-50 bg-text-black bg-opacity-75 backdrop-blur-md` : `hidden opacity-50 -z-50`}`}>
            <div className='flex items-center justify-center'>
                <ul className='flex flex-col items-center pt-4 lg:hidden'>
                    <NavLink onClick={toggle} href="#about" label="About" mobile />
                    <NavLink onClick={toggle} href="#portfolio" label="Portfolio" mobile />
                    <NavLink onClick={toggle} href="#process" label="Process" mobile />
                    <NavLink onClick={toggle} href="#contact" label="Contact" mobile />
                    <SocialLinks className="my-4" />
                </ul>
            </div>
        </div >
    )
}

export default MobileNavbar