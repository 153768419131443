import heroRight from './assets/img/hero-right.jpg'
import aboutImage from './assets/img/about-img.png'
import aboutSingle from './assets/img/about-02.png'
import React, { useEffect } from 'react';
import Button from './components/Button';
import ImageWrapper from './components/ImageWrapper';
import SocialLinks from './components/SocialLinks'
import Section from './components/Section'
import Navbar from './components/Navbar'
import VideoPlayer from './components/VideoPlayer'
import videoData from './assets/portfolio/videoData.js'
import TextCard from './components/TextCard';
import { ReactComponent as MailIcon } from './assets/icons/mail-icon.svg'
import { ReactComponent as CallIcon } from './assets/icons/call-icon.svg'
import ContactCard from './components/ContactCard';
import SectionTitle from './components/SectionTitle';
import AOS from 'aos'
import process from './utils/process';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div>
      <Navbar />

      {/* Hero */}

      <div className='w-full lg:h-screen flex flex-col justify-center bg-hero bg-cover bg-top'>
        <div className='relative text-center lg:text-start lg:pl-[10%] flex flex-col-reverse items-center lg:items-start justify-between'>
          <div data-aos="zoom-in" data-aos-duration="1000" className='z-10 text-white 2xl:ml-[10%]'>
            <p className='mt-2 text-lg'>My name is Ivaylo, I am a</p>
            <h1 className='uppercase text-transparent text-3xl sm:text-5xl lg:text-6xl xl:text-7xl bg-clip-text bg-gradient-to-r from-transparent to-white'>Videographer</h1>
            <p className='text-xl lg:text-2xl w-10/12 mx-auto lg:mx-0 xl:text-2xl py-4 xl:w-7/12'>My mission is to capture every motion and create a unique experience for my clients.</p>
            <div data-aos="fade-up" className='flex flex-col 2xl:flex-row mb-4 lg:items-start items-center w-full'>
              <Button href="#contact" className="lg:w-1/3">Contact me</Button>
              <Button href="#portfolio" className="lg:w-1/3" buttonStyle='ghost'>My work</Button>
            </div>
          </div>
          <ImageWrapper src={heroRight} alt="Hero Image" className='w-10/12 pt-[30%] z-0 md:pt-[15%] md:w-8/12 lg:right-[10%] lg:top-[20%] lg:-translate-y-1/2 lg:absolute lg:w-5/12 xl:w-2/6 2xl:w-1/4 2xl:top-0 2xl:right-[20%]' />
        </div>
      </div>

      <Section name="portfolio" animate={false}>
        <SectionTitle centered title="My Work" subtitle="portfolio" />
        <VideoPlayer videos={videoData}>
        </VideoPlayer>
      </Section>

      {/* About #01 */}

      <Section name="about" className="flex flex-col-reverse lg:flex-row items-center xl:items-start lg:px-[10%] 2xl:px-[15%]">
        < div data-aos="fade-up" className='text-center lg:text-start lg:w-8/12' >
          <SectionTitle title="Bringing Your Narrative to Life." subtitle="About me" />
          <p className='my-4 w-10/12 mx-auto lg:mx-0 text-text-gray text-xl'>As a professional videographer with over 5 years of experience, I have honed my craft in capturing stunning visuals and creating meaningful content for a variety of clients. From corporate videos to travel and adventure, I am dedicated to telling stories through the lens of my camera and creating a final product that exceeds expectations. My passion for videography and my eye for detail are evident in every project I undertake.</p>
        </div >
        <ImageWrapper src={aboutImage} alt="About Me" className='w-10/12 lg:w-auto mx-auto 2xl:ml-[5%]' />
      </Section>

      {/* About #02 */}

      <Section name="about" className="flex flex-col lg:flex-row items-center xl:items-start lg:px-[10%] 2xl:px-[15%]">
        <ImageWrapper src={aboutSingle} alt="About Me" className='w-8/12 lg:w-2/5 lg:mr-[5%]' />
        < div data-aos="fade-up" className='text-center lg:text-start lg:w-3/5' >
          <p className='my-4 w-10/12 mx-auto lg:mx-0 text-text-gray text-xl'> I am equipped with the latest in videography equipment, including high-end cameras, drone for aerial videography and professional editing software, to ensure the highest quality final product. My goal is to create a unique and memorable video that will tell your story and connect with your audience. I am always excited to take on new projects and to push the boundaries of what is possible with videography. </p>
          <div className='my-8'>
            <Button href='#contact'>Get In Touch</Button>
          </div>
          <SocialLinks className='justify-center lg:justify-start' />
        </div >
      </Section>

      {/* Work Process */}

      <Section name="process" className="bg-white lg:px-[10%] 2xl:px-[15%]">
        <SectionTitle centered title="Complete work process" subtitle="workflow" className="mb-12" />
        <div data-aos="fade-up" className='flex flex-col xl:flex-row items-center xl:items-start justify-between flex-wrap'>
          {
            process.map((step, index) => {
              return <TextCard key={step.id} className="w-10/12 xl:w-[30%]" label={index + 1} heading={step.heading} description={step.description} />
            })
          }
        </div>
        <div className='grid place-items-center mt-12'>
          <Button href='#contact' className="lg:w-1/3 2xl:w-1/3">Let's Talk</Button>
        </div>
      </Section>



      <Section name="contact" className="lg:px-[10%] 2xl:px-[20%] bg-white">
        <SectionTitle centered title="Get in touch with me" subtitle="contact" className="mb-12" description="Have a project in mind? Let's work together to create something truly special." />
        <div className='w-full pt-8 flex flex-col lg:flex-row items-center justify-between'>
          <ContactCard
            description="Email"
            link="ivayloevoivanov@gmail.com"
            href="mailto:ivayloevoivanov@gmail.com">
            <MailIcon className='stroke-text-black mx-auto' width={64} height={64} />
          </ContactCard>

          <ContactCard
            description="Phone"
            link="+359 899370377">
            <CallIcon className='stroke-text-black mx-auto' width={64} height={64} />
          </ContactCard>
        </div>
      </Section>
    </div >
  );
}

export default App;