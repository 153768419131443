import React from 'react'

function SectionTitle({ className, title, subtitle, centered = false, description }) {
    return (
        <div data-aos="fade-up" className={`${centered ? 'text-center' : ''} my-4 ${className}`}>
            <p className='uppercase text-brand mt-4 font-medium'>{subtitle}</p>
            <h2 className='w-10/12 mx-auto lg:w-full font-sq uppercase text-3xl lg:text-4xl'>{title}</h2>
            <p className='text-text-gray text-lg mt-2 w-10/12 lg:w-1/2 mx-auto'>{description}</p>
        </div>
    )
}

export default SectionTitle