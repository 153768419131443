import React from 'react'

function IconLink({ href, className, children, width, height }) {
    return (
        <a href={href} target="_blank" className={className}>
            {children}
        </a>
    )
}

export default IconLink