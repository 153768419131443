import React from 'react'
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook-icon.svg'
import { ReactComponent as YoutubeIcon } from '../assets/icons/youtube-icon.svg'
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram-icon.svg'
import IconLink from './IconLink'

function SocialLinks({ className }) {
    return (
        <div className={`flex items-center ${className}`}>
            <IconLink href="https://www.youtube.com/@EvoWorkout" className='cursor-pointer mx-2'>
                <YoutubeIcon className='stroke-text-gray hover:stroke-brand' width={24} height={24} />
            </IconLink>
            <IconLink href="https://www.instagram.com/storyou.official/" className='cursor-pointer mx-2'>
                <InstagramIcon className='stroke-text-gray hover:stroke-brand' width={24} height={24} />
            </IconLink>
            <IconLink href="https://www.facebook.com/ivaylo.ivanov.37" className='cursor-pointer mx-2'>
                <FacebookIcon className='stroke-text-gray hover:stroke-brand' width={24} height={24} />
            </IconLink>
        </div>
    )
}

export default SocialLinks