import React from 'react'

function Button({ children, buttonStyle = "default", className, href = "#" }) {
    const styles = {
        default: 'bg-brand text-white border-brand hover:bg-brand hover:bg-brand-opacity',
        ghost: 'bg-transparent text-brand border-brand hover:text-white hover:bg-brand-opacity',
    }

    return (
        <a href={href} className={`w-10/12 mx-auto text-center border lg:mx-0 py-4 px-20 rounded-md lg:mr-4 font-medium my-2 text-md lg:text-xl 2xl:w-auto ${styles[buttonStyle]} ${className}`}>{children}</a>
    )
}

export default Button