const vid01 = require('./01.mp4')
const vid03 = require('./03.mp4')
const vid05 = require('./05.mp4')
const vid06 = require('./06.mp4')
const vid07 = require('./07.mp4')
const vid08 = require('./08.mp4')
const vid09 = require('./09.mp4')
const vid10 = require('./10.mp4')
const vid11 = require('./11.mp4')
const vid12 = require('./12.mp4')
const vid13 = require('./13.mp4')
const vid14 = require('./14.mp4')
const vid15 = require('./15.mp4')
const thumbnail02 = require('../img/thumbnails/thumbnail-02.png')
const thumbnail04 = require('../img/thumbnails/thumbnail-04.png')
const thumbnail05 = require('../img/thumbnails/thumbnail-05.png')
const thumbnail06 = require('../img/thumbnails/thumbnail-06.png')
const thumbnail07 = require('../img/thumbnails/thumbnail-07.png')
const thumbnail08 = require('../img/thumbnails/thumbnail-08.png')
const thumbnail09 = require('../img/thumbnails/thumbnail-09.png')
const thumbnail10 = require('../img/thumbnails/thumbnail-10.png')
const thumbnail11 = require('../img/thumbnails/thumbnail-11.jpeg')
const thumbnail12 = require('../img/thumbnails/thumbnail-12.jpeg')
const thumbnail13 = require('../img/thumbnails/thumbnail-13.jpeg')
const thumbnail14 = require('../img/thumbnails/thumbnail-14.jpeg')
const thumbnail15 = require('../img/thumbnails/thumbnail-15.jpeg')

const videos = [
    {
        videoId: "15",
        label: "ONDA Pulse Fitness",
        src: vid15,
        thumbnail: thumbnail15
    },
    {
        videoId: "14",
        label: "Seven Hills",
        src: vid14,
        thumbnail: thumbnail14
    },
    {
        videoId: "13",
        label: "Convex",
        src: vid13,
        thumbnail: thumbnail13
    },
    {
        videoId: "01",
        label: "NOA Beauty",
        src: vid01,
        thumbnail: thumbnail10
    },
    {
        videoId: "11",
        label: "Gallery Complex",
        src: vid11,
        thumbnail: thumbnail11
    },
    {
        videoId: "12",
        label: "Царевец",
        src: vid12,
        thumbnail: thumbnail12
    },
    {
        videoId: "08",
        label: "Ivan Vazovo",
        src: vid08,
        thumbnail: thumbnail07
    },
    {
        videoId: "06",
        label: "Seven Suns",
        src: vid06,
        thumbnail: thumbnail05
    },
    {
        videoId: "05",
        label: "El Tubo",
        src: vid05,
        thumbnail: thumbnail04
    },
    {
        videoId: "03",
        label: "AutoKing",
        src: vid03,
        thumbnail: thumbnail02
    },
    {
        videoId: "07",
        label: "Riders Choice",
        src: vid07,
        thumbnail: thumbnail06
    },
    {
        videoId: "09",
        label: "Pirin Building",
        src: vid09,
        thumbnail: thumbnail08
    },
    {
        videoId: "10",
        label: "Kia Ceed GT",
        src: vid10,
        thumbnail: thumbnail09
    },
]

module.exports = videos