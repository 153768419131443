import React from 'react'

function NavLink({ href, label, mobile, onClick }) {
    return (
        <li>
            <a onClick={onClick} className={`text-white uppercase text-md lg:mx-4 lg:text-lg hover:text-brand ${mobile ? "block my-4" : ""} `} href={href}>{label}</a>
        </li>
    )
}

export default NavLink