import React from 'react'

function Section({ name, className, children, animate = true }) {
    return (
        <section data-aos={`${animate ? "fade-up" : ""}`} data-aos-duration="1500" id={name}>
            < div className={`w-full py-[10%] ${className}`} >
                {children}
            </div>
        </section>
    )
}

export default Section