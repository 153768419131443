import React from 'react'

function ImageWrapper({ className, src, alt, onClick, fill = false, children }) {
    return (
        <div onClick={onClick} className={className}>
            <img loading='lazy' className={`${fill ? 'min-h-full min-w-full shrink-0 object-cover' : ''} w-full`} src={src} alt={alt} />
            {children}
        </div>
    )
}

export default ImageWrapper