import React, { useEffect, useState } from 'react'
import MobileNavbar from './MobileNavbar';
import NavLink from './NavLink'
import SocialLinks from './SocialLinks'
import { ReactComponent as MenuIcon } from '../assets/icons/menu-icon.svg'
import { ReactComponent as CloseIcon } from '../assets/icons/close-icon.svg'
import ImageWrapper from './ImageWrapper';

function Navbar() {
    const [sidebar, setSidebar] = useState(false);

    const toggleSidebar = () => {
        setSidebar(!sidebar)
    }

    return (
        <nav data-aos="fade-down" className='z-10 w-full flex lg:py-8 2xl:py-12 items-center justify-center absolute top-0 bg-transparent border-none lg:border-b border-nav-border'>
            <ul className='hidden lg:flex items-center justify-center w-10/12 lg:w-1/4'>
                <NavLink href="#about" label="About" />
                <NavLink href="#portfolio" label="Portfolio" />
                <NavLink href="#process" label="Process" />
                <NavLink href="#contact" label="Contact" />
            </ul>
            <div className='flex flex-col items-start lg:hidden w-full '>
                <div className='w-10/12 mx-auto'>
                    <button onClick={toggleSidebar} className='my-8'>
                        {
                            sidebar ? <CloseIcon width={24} height={24} />
                                : <MenuIcon width={24} height={24} />
                        }
                    </button>
                </div>
                <MobileNavbar toggle={toggleSidebar} enabled={sidebar} />
            </div>
            <SocialLinks className='hidden lg:flex absolute right-[10%]' />
        </nav>
    )
}

export default Navbar